import {ChangeDetectorRef, Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {DossierService} from './dossier.service';
import {Dossier, EtatDossier, NatureDossier} from './dossier.model';
import {ListeReferenceService} from '../../shared/liste-reference/liste-reference.service';
import {Principal} from '../../shared/auth/principal.service';
import {User} from '../../shared/user/user.model';
import {ActivatedRoute, Router} from '@angular/router';
import {JhiEventManager} from 'ng-jhipster';
import {Subscription} from 'rxjs/Subscription';
import {ResponseWrapper} from '../../shared/model/response-wrapper.model';
import {ConfirmationService} from 'primeng/primeng';
import {IPrimeNgConfigCalendarFr, PRIMENG_CONFIG_CALENDAR_FR} from '../../config/app.primeng.config.calendar.fr';
import {DossierPopupService} from './dossier-popup.service';
import {DossierArchivageDialogComponent} from './dossier-archivage-dialog.component';
import {Fichier} from '../../shared/file/file.model';
import {Observable} from 'rxjs/Observable';
import {SocieteMandataire} from '../societeMandataire/societe-mandataire.model';
import fileSaver = require('file-saver');

@Component({
    selector: 'dm-dossier-new',
    templateUrl: './dossier-new.component.html',
    styleUrls: [
        'dossier-new.scss'
    ]
})
export class DossierNewComponent implements OnInit, OnDestroy {

    dossier: Dossier;
    /** profil du user en session */
    profil: string;
    /** Liste de ref natures */
    natures: any[];
    isSaving: boolean;
    isPjSaving: boolean;
    isNatureChange: boolean = false;
    isTransmis: boolean = false;
    isRefusManquantMissing: boolean;
    delaiReglementaires: any[];
    maxCaracteresSociete = 70;
    maxCaracteresProduit = 100;
    maxCaracteresCommentaireDemandeur = 2000;
    maxCaracteresCommentaireAdmin = 1000;
    maxCaracteresInfoComplementaires = 2000;
    maxCaracteresElementsComplementaires = 5000;
    maxCaracteresMotivationRefus = 1000;

    private eventSubscriberPieceJointes: Subscription;
    private eventSubscriberArchivage: Subscription;

    yearRange: string = ''; // Plage d'années dynamiquement calculée

    constructor(
        private dossierService: DossierService,
        private listeReferenceService: ListeReferenceService,
        private principal: Principal,
        private router: Router,
        private route: ActivatedRoute,
        private confirmationService: ConfirmationService,
        private dossierPopupService: DossierPopupService,
        private cdRef: ChangeDetectorRef,
        /** traduction DatePicker */
        @Inject(PRIMENG_CONFIG_CALENDAR_FR) public fr: IPrimeNgConfigCalendarFr,
        private eventManager: JhiEventManager
    ) {
        this.yearRange = this.getDynamicYearRange();

    }

    ngOnInit() {

        this.listeReferenceService.get(this.listeReferenceService.DELAI_REGLEMENTAIRE).subscribe((delais) => {
            this.delaiReglementaires = delais;
        });

        // Ecoute l'Action à chaque modification de pièce jointe
        this.registerChangeInPieceJointes();
        this.registerChangeInDossier();

        this.route.params.subscribe((params) => {

            const dossierId = params.id;

            // Création d'un nouveau dossier
            this.principal.identity().then((account: User) => {
                this.profil = account.authorities[0];

                this.getDossier(dossierId, account).subscribe((dossier) => {
                    this.dossier = dossier;
                    this.getNatures();
                    console.log('getdossier');
                    console.log(this.dossier);

                    // @ts-ignore
                    this.isTransmis = EtatDossier[dossier.etat] === EtatDossier.TRANSMIS;
                    this.isNatureChange = dossier.natureChange;
                });
            });

        });

    }

    private getDossier(id: number, account: User): Observable<Dossier> {
        if (id) {
            return this.dossierService.find(id);
        } else {
            // Initialisation d'un nouveau dossier
            const dossier = new Dossier();
            dossier.etat = EtatDossier.EN_COURS;
            dossier.nomSociete = account.utilisateurDm.nomSociete.toUpperCase();
            dossier.utilisateurCreation = account;
            if (account.utilisateurDm.fonction == 'CONSULTANT') {
                dossier.societeMandataire = new SocieteMandataire();
            }

            return Observable.of(dossier);
        }
    }

    private getNatures() {
        this.listeReferenceService.get(this.listeReferenceService.NATURE_DOSSIER).subscribe((natures) => {
            if (!this.dossier || !this.dossier.id) {
                // Cas création => Toutes les valeurs possibles
                this.natures = natures;
            } else if (this.dossier.nature === NatureDossier.MODIFICATION || this.dossier.nature === NatureDossier.INSCRIPTION) {
                // Inscription et Modification dans la liste
                this.natures = natures.filter((nature) => nature.value === NatureDossier.MODIFICATION
                    || nature.value === NatureDossier.INSCRIPTION
                    || nature.value === NatureDossier.RADIATION
                    || nature.value === NatureDossier.RENOUV_INSCRIPTION);
            } else if (this.dossier.nature === NatureDossier.RENOUV_INSCRIPTION) {
                this.natures = natures.filter((nature) => nature.value === NatureDossier.RENOUV_INSCRIPTION || nature.value === NatureDossier.INSCRIPTION);
            } else {
                // Uniquement la nature courante dans la liste
                this.natures = natures.filter((nature) => nature.value === this.dossier.nature);
            }

        });
    }

    registerChangeInPieceJointes() {
        this.eventSubscriberPieceJointes = this.eventManager.subscribe('pieceJointeListModification', (response) => {
            this.loadPieceJointes();
            // rechargement du dossier pour actualiser la date et l'utilisateur de la dernière modification
            this.dossierService.find(this.dossier.id).subscribe((dossier: Dossier) => {
                this.actualisationDateEtUser(dossier, this.dossier);
            });
        });
    }

    /**
     * récupère le dossierReponse et actualise la date et l'utilisateur de la dernière modification du dossierActual
     * @param dossierReponse
     * @param dossierActual
     */
    actualisationDateEtUser(dossierReponse: Dossier, dossierActual: Dossier){
        dossierActual.utilisateurModification = dossierReponse.utilisateurModification;
        dossierActual.dateModification = dossierReponse.dateModification;
    }
    registerChangeInDossier() {
        this.eventSubscriberArchivage = this.eventManager.subscribe('dossierListModification', (response) => {
            const dossier: Dossier = response.content;
            this.dossier.dateArchivage = dossier.dateArchivage;
            this.dossier.commentaireArchivage = dossier.commentaireArchivage;
        });
    }

    ngOnDestroy() {
        this.eventManager.destroy(this.eventSubscriberPieceJointes);
        this.eventManager.destroy(this.eventSubscriberArchivage);
    }

    loadPieceJointes() {
        this.dossierService.getPieceJointes(this.dossier.id).subscribe(
            (res: ResponseWrapper) => {
                const pjs = this.dossier.pieceJointes;
                this.dossier.pieceJointes = res.json;
            });
    }

    /**
     * Met à jour la propriété isPjSaving selon le nombre de pièces jointes en cours de sauvegarde
     */
    updatePjSaving(nbPjSaving: number) {
        this.isPjSaving = nbPjSaving > 0;
    }

    /**
     * Sauvegarde la dossier
     * Appelé à la soumission du formulaire
     */
    sauvegarderDossier(dossier: Dossier) {
        this.isSaving = true;
        dossier.natureChange = this.isNatureChange;

        if (!dossier.id) {
            this.dossierService.init(dossier).subscribe((dossierSaved) => {
                this.dossier = dossierSaved;
                this.isSaving = false;
                // @ts-ignore
                this.isTransmis = EtatDossier[this.dossier.etat] === EtatDossier.TRANSMIS;
                this.router.navigate([`/dossiers/${this.dossier.id}/edit`]);
                console.log('save');
                console.log(dossierSaved);
            }, () => {
                this.isSaving = false;
            });
        } else {
            this.dossierService.update(dossier).subscribe((dossierSaved) => {
                this.dossier = dossierSaved;
                this.isSaving = false;
                // @ts-ignore
                this.isTransmis = EtatDossier[this.dossier.etat] === EtatDossier.TRANSMIS;
            }, () => {
                this.isSaving = false;
            });
        }
    }

    /**
     * Demande d'infos complémentaire
     * @param dossier
     */
    demanderInfos(dossier: Dossier) {
        this.isSaving = true;
        this.dossierService.demanderInfos(dossier).subscribe((dossierSaved) => {
            this.dossier = dossierSaved;
            this.isSaving = false;
        }, () => {
            this.isSaving = false;
        });
    }

    /**
     * Demande d'infos complémentaire
     * @param dossier
     */
    arInfoComplementaire(dossier: Dossier) {
        this.isSaving = true;
        this.dossierService.arInfos(dossier).subscribe((dossierSaved) => {
            this.dossier = dossierSaved;
            this.isSaving = false;
        }, () => {
            this.isSaving = false;
        });
    }

    /**
     * Valider un dossier
     * @param dossier
     */
    valider(dossier: Dossier) {
        this.confirmerChangementEtat(dossier, EtatDossier.VALIDE, 'valider');
    }

    /**
     * Refuser un dossier
     * @param dossier
     */
    refuser(dossier: Dossier) {
        if (!dossier.motivationRefus) {
            this.isRefusManquantMissing = true;
        } else {
            this.isRefusManquantMissing = false;
            this.confirmationService.confirm({
                message: `Voulez-vous vraiment refuser ce dossier?`,
                accept: () => {
                    this.isSaving = true;
                    this.dossierService.refuser(dossier).subscribe((dossierSaved) => {
                        this.dossier = dossierSaved;
                        this.isSaving = false;
                    }, () => {
                        this.isSaving = false;
                    });
                }
            });
        }
    }

    /**
     * Compléter un dossier en renseignant des infos supplémentaires, sans changement d'état ni AR
     */
    completer(dossier: Dossier) {
        this.dossierService.completer(dossier).subscribe((dossierSaved) => {
            this.dossier = dossierSaved;
        });
    }

    /**
     * Demander la confirmation puis changer l'état d'un dossier
     * @param dossier
     * @param etat
     * @param label
     */
    confirmerChangementEtat(dossier: Dossier, etat: EtatDossier, label: string) {
        this.confirmationService.confirm({
            message: `Voulez-vous vraiment ${label} ce dossier?`,
            accept: () => {
                this.isSaving = true;
                this.dossierService.changerEtat(dossier.id, etat).subscribe((dossierSaved) => {
                    this.dossier = dossierSaved;
                    this.isSaving = false;
                }, () => {
                    this.isSaving = false;
                });
            }
        });
    }

    /**
     * Sauvegarde et transmet le dossier
     * @param dossier
     */
    tranmettreDossier(dossier: Dossier) {
        this.isSaving = true;
        this.dossierService.transmettre(dossier).subscribe((dossierSaved) => {
            this.dossier = dossierSaved;
            this.isSaving = false;
        }, () => {
            this.isSaving = false;
        });

    }

    /**
     * Créer un pdf à partir du Dossier
     * @param id
     */
    exportToPdf(id: number) {
        this.dossierService.exportPdf(id).subscribe((fichier: Fichier) => {
            fileSaver.saveAs(fichier.blob, fichier.name);
        });
    }

    /**
     * Supprimer un dossier non transmis
     *
     */
    supprimer(id: number) {
        this.confirmationService.confirm({
            message: 'Voulez-vous vraiment supprimer ce dossier?',
            accept: () => {
                this.dossierService.delete(id).subscribe((res) => {
                    this.router.navigate([`/dossier`]);
                });
            }
        });
    }

    /**
     * Archiver / Désarchiver un dossier
     * @param dossier
     */
    toggleArchivage(dossier: Dossier) {
        if (dossier.dateArchivage) {
            // CONFIRMER LE DESARCHIVAGE
            this.confirmationService.confirm({
                message: 'Voulez-vous vraiment désarchiver ce dossier?',
                accept: () => {
                    this.isSaving = true;
                    this.dossierService.desarchiver(dossier.id).subscribe((dossierSaved) => {
                        this.dossier = dossierSaved;
                        this.isSaving = false;
                    }, () => {
                        this.isSaving = false;
                    });
                }
            });
        } else {
            // ARCHIVER
            this.dossierPopupService.open(DossierArchivageDialogComponent, Object.assign({}, this.dossier));
        }
    }

    /**
     * Duplique un dossier
     * @param {Dossier} dossier
     */
    dupliquer(dossier: Dossier) {
        this.confirmationService.confirm({
            message: 'Voulez-vous vraiment dupliquer ce dossier?',
            accept: () => {
                this.dossierService.dupliquer(dossier).subscribe((dossierDuplique: Dossier) => {
                    this.router.navigate([`/dossiers/${dossierDuplique.id}/edit`]);
                });
            }
        });
    }

    /**
     * Handler au changement de nature
     */
    onNatureChange(event: any) {
        let oldNature = this.dossier.nature;

        if (!this.dossier.id) {
            this.dossier.nature = event;
            // Dossier en init pas de confirmation requise
            return;
        }

        this.confirmationService.confirm({
            message: 'Voulez-vous vraiment modifier la nature du dossier et le sauvegarder?',
            accept: () => {
                this.dossier.nature = event;
                this.dossierService.updateNature(this.dossier).subscribe((dossierSaved) => this.dossier = dossierSaved);
                this.getNatures();
            },
            reject: () => {
                this.dossier.nature = oldNature;
            }
        });
        this.isNatureChange = true;

    }

    /**
     * Génère un accusé de reception concernant le dossier de l'id soumis
     */
    genererAR(dossier: Dossier) {
        this.isSaving = true;
        if (dossier.id) {
            this.sauvegarderDossier(dossier);
            this.dossierService.genererAR(dossier.id).subscribe((dossier: Dossier) => {
                this.dossier = dossier;
                this.isSaving = false;
            });
        } else {
            this.isSaving = false;
        }
    }
    /**
     * Cette méthode calcule une plage d'années dynamiques basée sur l'année actuelle.
     * Elle retourne une chaîne au format "année minimale-année maximale".
     *
     * @returns {string} Une chaîne représentant la plage d'années (par exemple : "2014-2074").
     */
    getDynamicYearRange(): string {
        // Obtient l'année actuelle à partir de la date du jour
        const currentYear = new Date().getFullYear();
        // Calcule l'année minimale en soustrayant 10 ans à l'année actuelle
        const minYear = currentYear - 10; // Exemple : 10 ans dans le passé
        // Calcule l'année maximale en ajoutant 50 ans à l'année actuelle
        const maxYear = currentYear + 50; // Exemple : 10 ans dans le futur
        // Retourne la plage d'années au format "minYear-maxYear"
        return `${minYear}:${maxYear}`;
    }

}
